import { reactive, ref } from 'vue'

const coverageCheckResult: any = reactive({})
const coverage: any = ref(false)
const loading: any = ref(false)
const error: any = ref(false)
const geocodeResult: any = reactive({})
const isConfirmed: any = ref(false)
const customer: any = reactive({})
const relocationFee: any = reactive({})
const formData: any = ref(false)
const token: any = reactive({})
const relocationId: any = reactive({})

export { coverageCheckResult, coverage, loading, error, geocodeResult, isConfirmed, formData, relocationFee, token, relocationId, customer }
export default { coverageCheckResult, coverage, loading, error, geocodeResult, isConfirmed, formData, relocationFee, token, relocationId, customer }
