

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'
import state, { geocodeResult, loading, coverageCheckResult } from '../state'
import coverageCheckService from '@/services/CoverageCheckService.ts'
import { l } from '@/constants'
import { defineComponent, watch } from 'vue'

export default defineComponent({
  name: 'CoverageChecker',

  data (): unknown {
    return {
      labels: l
    }
  },

  methods: {
    nextPage () {
      this.$router.push('/personal-details')
    }
  },

  mounted (): void {
    loading.value = false
    this.geocoder.addTo('#geocoder')

    watch(state.coverage, (newValue: any) => {
      if (coverageCheckResult.value.in_coverage) {
        this.nextPage()
      }
    })
  },

  setup (): any {
    coverageCheckService()

    if (!process.env.VUE_APP_MAPBOX_KEY) {
      throw new Error('the mapbox api key is missing')
    }

    // https://github.com/mapbox/mapbox-gl-geocoder/blob/master/API.md
    const geocoder = new MapboxGeocoder({
      accessToken: process.env.VUE_APP_MAPBOX_KEY,
      types: 'address',
      placeholder: 'Enter Your Address',
      bbox: [174.217546, -38.965732, 177.403581, -36.586777] // Boundary for Lightwire Rural
    })

    geocoder.on('loading', () => { loading.value = true })
    geocoder.on('result', (geocoder) => {
      loading.value = false
      geocodeResult.value = geocoder.result
    })
    geocoder.on('clear', () => {
      loading.value = false
      coverageCheckResult.value = {}
    })

    return { geocoder, coverageCheckResult, loading }
  }
})
