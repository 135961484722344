import axios from 'axios'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const mixpanel = require('mixpanel-browser')

export const mixpanel_lw = {
  mixpanelReady: false,
  mixpanelKey: process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN,
  customerId: '',
  init (customerId) {
    if (this.mixpanelKey) {
      mixpanel.init(this.mixpanelKey, { batch_requests: true })
      mixpanel.people.set(customerId)

      this.customerId = customerId
      this.mixpanelReady = true
    }
  },
  /**
   * @description sets a custom event
   * @param title {string} a human friendly title for the event
   * @param $event {object} data to send with the track event
   */
  setTrack (title, $event) {
    if (!this.mixpanelReady) {
      console.warn('mixpanel failed to initialize')
      return
    }

    const d = new Date()

    return new Promise((resolve, reject) => {
      mixpanel.track(
        title,
        {
          ...$event,
          ...{
            location: window.location.href,
            user_time: d.toString(),
            customer_id: this.customerId
          }
        }
      )

      resolve(true)
    })
  },
  /**
   * @description flatten the nested profile object so mixpanel can read it
   * @todo update this to handle arrays better
   */
  flatten (object) {
    return Object.assign({}, ...(function _flatten (objectBit, path = '') {
      return [].concat(
        ...Object.keys(objectBit).map(
          key => typeof objectBit[key] === 'object'
            ? _flatten(objectBit[key], `${path}/${key}`)
            : ({ [`${path}/${key}`]: objectBit[key] })
        )
      )
    }(object)))
  }
}
