import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import CoverageChecker from '@/components/CoverageChecker.vue'
import PersonalDetailsForm from '@/components/PersonalDetailsForm.vue'
import Confirmation from '@/components/Confirmation.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'CoverageChecker',
    component: CoverageChecker
  },
  {
    path: '/personal-details',
    name: 'PersonalDetailsForm',
    component: PersonalDetailsForm
  },
  {
    path: '/confirmation',
    component: Confirmation
  }
  // {
  //   path: '/no-result',
  //   name: 'NoResult',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/NoResult.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
