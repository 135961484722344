import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "p-grid" }
const _hoisted_2 = {
  key: 0,
  class: "confirmation-summary p-col-12 p-text-center"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 2,
  class: "p-grid p-nogutter"
}
const _hoisted_6 = {
  key: 1,
  class: "disconnection-summary p-col-12 p-text-center"
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 2,
  class: "p-grid p-nogutter"
}
const _hoisted_10 = {
  key: 0,
  class: "p-text-center"
}
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Divider = _resolveComponent("Divider")!
  const _component_Button = _resolveComponent("Button")!
  const _component_progress_spinner = _resolveComponent("progress-spinner")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.formData.is_disconnection)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.formData.contact_name) + ", " + _toDisplayString(_ctx.labels.CONFIRM_TITLE), 1),
            _createVNode(_component_Divider, { class: "p-my-6" }),
            _createElementVNode("p", null, _toDisplayString(_ctx.labels.CONFIRM_MOVING_TO), 1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.geocodeResult.value['place_name_en-GB']), 1)
            ]),
            _createElementVNode("p", null, _toDisplayString(_ctx.labels.CONFIRM_MOVING_FROM), 1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.customer.value.customer_address), 1)
            ]),
            _createElementVNode("p", null, _toDisplayString(_ctx.labels.CONFIRM_MOVING_DATE), 1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, _toDisplayString(new Date(_ctx.formData.moving_date).toLocaleString('en-GB').split(',')[0]), 1)
            ]),
            _createElementVNode("p", null, _toDisplayString(_ctx.labels.CONFIRM_CONTRACT_TERM), 1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.formData.contract_term) + " " + _toDisplayString(_ctx.labels.CONFIRM_CONTRACT_MONTHS), 1)
            ]),
            _createElementVNode("p", null, _toDisplayString(_ctx.labels.CONFIRM_EMAIL), 1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.formData.contact_email), 1)
            ]),
            _createElementVNode("p", null, _toDisplayString(_ctx.labels.CONFIRM_OR), 1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.formData.contact_cellphone), 1)
            ]),
            (_ctx.formData.contract_term === 6)
              ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.labels.CONFIRM_RELOCATION_FEE) + " $" + _toDisplayString(_ctx.relocationFee.value), 1))
              : _createCommentVNode("", true),
            (_ctx.formData.contract_term !== 6)
              ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.labels.CONFIRM_NO_RELOCATION_FEE), 1))
              : _createCommentVNode("", true),
            _createVNode(_component_Divider, { class: "p-my-6" }),
            (!_ctx.loading && !_ctx.showCompletionModal)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode(_component_Button, {
                    class: "p-d-block p-mx-auto",
                    label: _ctx.labels.CONFIRM_BUTTON,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.confirm()))
                  }, null, 8, ["label"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true),
      (_ctx.formData.is_disconnection)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("h1", null, _toDisplayString(_ctx.formData.contact_name) + ", " + _toDisplayString(_ctx.labels.CONFIRM_DISCONNECTION_TITLE), 1),
            _createVNode(_component_Divider, { class: "p-my-6" }),
            _createElementVNode("p", null, _toDisplayString(_ctx.labels.CONFIRM_DISCONNECTION_NEW_ADDRESS), 1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.geocodeResult.value['place_name_en-GB']), 1)
            ]),
            _createElementVNode("p", null, _toDisplayString(_ctx.labels.CONFIRM_DISCONNECTION_OLD_ADDRESS), 1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.customer.value.customer_address), 1)
            ]),
            _createElementVNode("p", null, _toDisplayString(_ctx.labels.CONFIRM_EMAIL), 1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.formData.contact_email), 1)
            ]),
            _createElementVNode("p", null, _toDisplayString(_ctx.labels.CONFIRM_OR), 1),
            _createElementVNode("p", null, [
              _createElementVNode("strong", null, _toDisplayString(_ctx.formData.contact_cellphone), 1)
            ]),
            (_ctx.formData.call_back)
              ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.labels.CONFIRM_CALLBACK_TRUE), 1))
              : _createCommentVNode("", true),
            (!_ctx.formData.call_back)
              ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.labels.CONFIRM_CALLBACK_FALSE), 1))
              : _createCommentVNode("", true),
            _createVNode(_component_Divider, { class: "p-my-6" }),
            (!_ctx.loading && !_ctx.showCompletionModal)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_Button, {
                    class: "p-d-block p-mx-auto",
                    label: _ctx.labels.CONFIRM_DISCONNECTION_BUTTON,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.confirm()))
                  }, null, 8, ["label"])
                ]))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.loading && !_ctx.showCompletionModal)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(_component_progress_spinner, { class: "p-mx-auto" })
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_Dialog, {
      visible: _ctx.showCompletionModal,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.showCompletionModal) = $event)),
      closable: false
    }, {
      header: _withCtx(() => [
        _createElementVNode("h3", null, _toDisplayString(_ctx.labels.CLOSE_WINDOW_MODAL_TITLE), 1)
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.closeWindow())),
          label: _ctx.labels.CLOSE_WINDOW_MODAL_BUTTON,
          icon: "pi pi-check",
          autofocus: ""
        }, null, 8, ["label"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", {
          class: "p-text-center",
          innerHTML: _ctx.labels.CLOSE_WINDOW_MODAL_NEXT
        }, null, 8, _hoisted_11),
        _createElementVNode("p", {
          class: "p-text-center",
          innerHTML: _ctx.labels.CLOSE_WINDOW_MODAL_DESCRIPTION
        }, null, 8, _hoisted_12)
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}