import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import Card from 'primevue/card'
import Divider from 'primevue/divider'
import Steps from 'primevue/steps'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import ProgressSpinner from 'primevue/progressspinner'
import InputMask from 'primevue/inputmask'
import Dropdown from 'primevue/dropdown'
import Checkbox from 'primevue/checkbox'
import Dialog from 'primevue/dialog'

import 'primeflex/primeflex.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

createApp(App)
  .use(router)
  .use(PrimeVue)
  .use(ToastService)
  .component('ProgressSpinner', ProgressSpinner)
  .component('Button', Button)
  .component('Calendar', Calendar)
  .component('Card', Card)
  .component('Steps', Steps)
  .component('Toast', Toast)
  .component('InputText', InputText)
  .component('InputNumber', InputNumber)
  .component('Divider', Divider)
  .component('InputMask', InputMask)
  .component('Dropdown', Dropdown)
  .component('Checkbox', Checkbox)
  .component('Dialog', Dialog)
  .mount('#app')
