
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { getRelocationCustomer } from '../services/RelocationService'
import { loading, relocationFee, formData, token, customer } from '../state'

export default {
  name: 'RelocationStepper',
  components: {},

  mounted (): void {
    loading.value = true

    /**
     * for testing only, create a JWT for a test customer then use it to lookup the customer
     */
    const urlParams = new URLSearchParams(window.location.search)
    const jwt = urlParams.get('jwt')

    if (jwt) {
      token.value = jwt
      getRelocationCustomer(jwt).then((r: any) => {
        if (r.data) {
          customer.value = r.data.customer || {}
        }
      })
    } else {
      console.error('invalid or missing token')
    }

    loading.value = false
  },
  setup (): any {
    const router = useRouter()
    const formObject: any = ref({})
    const items = ref([
      {
        label: '1',
        to: '/'
      },
      {
        label: '2',
        to: '/personal-details'
      },
      {
        label: '3',
        to: '/confirmation'
      }
    ])

    const nextPage = (event) => {
      for (const field in event.formData) {
        formObject.value[field] = event.formData[field]
      }

      if (formObject.value.valid) {
        router.push(items.value[event.pageIndex + 1].to)
      }
    }

    const prevPage = (event) => {
      router.push(items.value[event.pageIndex - 1].to)
    }

    const complete = () => {
      return false
    }

    return { items, formObject, nextPage, prevPage, complete, relocationFee, formData, token }
  }
}
