import axios from 'axios'
import state from '../state'
import { mixpanel_lw } from '@/services/MixPanelTracker'

const celium = axios.create()
celium.defaults.baseURL = process.env.VUE_APP_CELIUM_BASE_URL

celium.defaults.headers.common = {
  'Content-Type': 'application/json; charset=utf-8',
  Accept: 'application/json'
}

/**
 * @description create the request
 */
export function getRelocationCustomer (jwt) {
  state.loading.value = true

  return celium.get(`rural/relocation?jwt=${jwt}`)
    .then((res) => {
      state.loading.value = false
      state.relocationFee.value = res.data.relocation_fee.toFixed(2)

      mixpanel_lw.init(res.data.customer.customer_id)
      return res
    }).catch(() => {
      state.loading.value = false
      state.error.value = true
    })
}

/**
 * @description create the request
 */
export function createRelocationService (formData: any) {
  state.loading.value = true

  return celium.post(`rural/relocation?jwt=${state.token.value}`, formData).then((res) => {
    state.relocationId.value = res.data.data.relocations_id
    state.loading.value = false

    trackSubmission()
    return res
  })
}

/**
 * @description confirm the request
 */
export function updateRelocationService () {
  state.loading.value = true

  return celium.put(`rural/relocation/${state.relocationId.value}`, {
    is_lead: false,
    jwt: state.token.value,
    call_back: state.formData.value.call_back
  }).then((res) => {
    state.isConfirmed = true
    state.loading.value = false
    trackConfirmation()
    return res
  }).catch(() => {
    state.isConfirmed = false
    state.error.value = true
  })
}

function trackSubmission () {
  if (!state.coverageCheckResult.value?.in_coverage) {
    mixpanel_lw.setTrack(
      'RURAL_RELOCATION_FORM_SUBMITTED - NO COVERAGE',
      {
        type: 'FORM_SUBMITTED',
        detail: 'The Rural Relocation form was submitted',
        callback: state.formData.value.call_back
      }
    )
  } else {
    mixpanel_lw.setTrack(
      'RURAL_RELOCATION_FORM_SUBMITTED - IN COVERAGE',
      {
        type: 'FORM_SUBMITTED',
        detail: 'The Rural Relocation form was submitted'
      }
    )
  }
}

function trackConfirmation () {
  if (!state.coverageCheckResult.value?.in_coverage) {
    mixpanel_lw.setTrack(
      'RURAL_RELOCATION_CONFIRMED - NO COVERAGE',
      {
        type: 'FORM_SUBMITTED',
        detail: 'The relocation form details were confirmed',
        callback: state.formData.call_back
      }
    )
  } else {
    mixpanel_lw.setTrack(
      'RURAL_RELOCATION_CONFIRM - IN COVERAGE',
      {
        type: 'FORM_SUBMITTED',
        detail: 'The relocation form details were confirmed'
      }
    )
  }
}
