import axios from 'axios'
import state from '../state'
import { watch } from 'vue'
import { mixpanel_lw } from '@/services/MixPanelTracker'

const celium = axios.create()
celium.defaults.baseURL = process.env.VUE_APP_CELIUM_BASE_URL

export default function coverageCheckService () {
  watch(state.geocodeResult, (newValue: any) => {
    state.loading.value = true

    celium.post('prequalification/gis', {
      longitude: newValue?.value.geometry.coordinates[0],
      latitude: newValue?.value.geometry.coordinates[1],
      with_geojson: false,
      source: 'customer',
      address: newValue.value.place_name
    }).then((result) => {
      state.coverageCheckResult.value = result.data

      state.loading.value = false
      state.coverage.value = true

      trackCoverage(state.coverageCheckResult.value?.in_coverage)
    }).catch(() => {
      state.error.value = true
      trackCoverage(false)
    })
  })
}

function trackCoverage (inCoverage) {
  mixpanel_lw.setTrack(
    'RURAL_RELOCATION_COVERAGE_CHECK',
    {
      type: 'FORM_SUBMITTED',
      detail: 'The Rural Relocation Coverage Checkers was used',
      in_coverage: inCoverage
    }
  )
}
