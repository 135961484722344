
import { l } from '@/constants'
import { defineComponent, ref } from 'vue'
import state, { formData, geocodeResult, coverageCheckResult, token, customer, relocationFee, loading } from '../state'
import { createRelocationService } from '../services/RelocationService'

interface ValidationErrors {
  contact_name?: boolean | null;
  contact_cellphone?: boolean | null;
  contact_email?: boolean | null;
  moving_date?: boolean | null;
  property_levels?: boolean | null;
}

export default defineComponent({
  name: 'PersonalDetailsForm',
  emits: ['next-page'],
  setup () {
    const inCoverage = coverageCheckResult.value?.in_coverage

    const minDateValue = ref(new Date(new Date().setDate(new Date().getDate())))
    const maxDateValue = ref(new Date(new Date().setFullYear(new Date().getFullYear() + 2)))

    return { coverageCheckResult, inCoverage, minDateValue, maxDateValue, customer, token, relocationFee, loading }
  },
  data () {
    const ve: ValidationErrors = {}

    return {
      contact_name: customer.value?.name || '',
      contact_cellphone: customer.value?.phone || '',
      contact_email: customer.value?.email || '',
      moving_date: '',
      property_levels: 0,
      call_back: true,
      submitted: false,
      is_lead: true,
      is_disconnection: (!coverageCheckResult.value?.in_coverage),
      contract_term: 12,
      validationErrors: ve,
      isTouch: matchMedia('(hover: none), (pointer: coarse)').matches,
      labels: l,
      terms: [
        { name: '12 months - $0.00 install', code: 12 },
        { name: `6 months - $${relocationFee.value} install`, code: 6 }
      ]
    }
  },
  methods: {
    nextPage () {
      this.submitted = true

      if (this.validateForm()) {
        loading.value = true

        formData.value = {
          contact_name: this.contact_name,
          contact_cellphone: this.contact_cellphone,
          contact_email: this.contact_email,
          moving_date: this.moving_date,
          property_levels: this.property_levels,
          submitted: false,
          is_lead: true,
          call_back: this.call_back,
          is_disconnection: (!coverageCheckResult.value?.in_coverage),
          contract_term: this.contract_term
        }

        const postData = this.prepareForm()

        createRelocationService(postData).then((res) => {
          loading.value = false
          this.$emit('next-page', { formData: { valid: true }, pageIndex: 1 })
        }).catch((err) => {
          if (err.response) {
            loading.value = false
            this.validateApiResponse(err.response)
          }
        })
      }
    },

    validateApiResponse (res) {
      for (const [key, value] of Object.entries(res.data.errors)) {
        this.validationErrors[key] = true
      }

      return !Object.keys(this.validationErrors).length
    },

    validateForm () {
      // eslint-disable-next-line no-control-regex
      const emailRegEx = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

      if (!this.contact_name) {
        this.validationErrors.contact_name = true
      } else {
        delete this.validationErrors?.contact_name
      }

      if (!this.contact_cellphone) {
        this.validationErrors.contact_cellphone = true
      } else {
        delete this.validationErrors?.contact_cellphone
      }

      if (
        !this.contact_email ||
        !emailRegEx.test(this.contact_email.toLowerCase())
      ) {
        this.validationErrors.contact_email = true
      } else {
        delete this.validationErrors?.contact_email
      }

      if (!this.moving_date) {
        this.validationErrors.moving_date = true
      } else {
        delete this.validationErrors?.moving_date
      }

      if (!this.property_levels && !this.is_disconnection) {
        this.validationErrors.property_levels = true
      } else {
        delete this.validationErrors?.property_levels
      }

      return !Object.keys(this.validationErrors).length
    },

    submitPropertyDetail (e: string): void {
      const val = (e === 'single') ? 1 : 2
      this.property_levels = val
    },

    prepareForm () {
      const parseJwt = (t) => {
        try {
          return JSON.parse(atob(t.split('.')[1]))
        } catch (e) {
          return null
        }
      }

      if (!geocodeResult.value || !token.value) {
        console.error('missing geocodeResult or token')
        return false
      }

      if (!parseJwt(token.value).customer_id) {
        console.error('invalid token')
        return false
      }

      if (!parseJwt(coverageCheckResult.value.jwt).lookup_id) {
        console.error('invalid lookup')
        return false
      }

      return {
        lookup_id: parseJwt(coverageCheckResult.value.jwt).lookup_id.toString(),
        customer_id: parseJwt(token.value).customer_id,
        sales_person: 'online',
        latitude: geocodeResult.value.center[1],
        longitude: geocodeResult.value.center[0],
        previous_address: customer.value.customer_address,
        is_lead: true,
        address: geocodeResult.value.place_name,
        ...formData.value
      }
    }
  }
})
