export const l = {
  COVERAGE_TITLE: 'MOVING? REGISTER YOUR MOVE NOW AND STAY CONNECTED',
  COVERAGE_SUB_TITLE: 'We\'re here to help you stay connected to great rural internet. If you\'re planning to move soon, we need plenty of notice to prepare and help you get set up quickly at the new place.',
  COVERAGE_RELOCATION_FEE: 'Please note that a relocation fee may apply',
  COVERAGE_NO_LINE_1: 'Unfortunately it looks like we do not have coverage in your area.',
  COVERAGE_NO_LINE_2: 'Please remember to lodge a cancellation request with us for your current connection if required,<br/>to do this you will need to call us on <a href="tel:0800121314">0800 12 13 14</a> or email <a href="mailto:sales@lightwire.co.nz">sales@lightwire.co.nz</a>',
  COVERAGE_ERROR_LINE_1: 'Unfortunately it looks like we aren\'t able to check coverage at the moment.',
  COVERAGE_ERROR_LINE_2: 'If you would like to call our helpdesk on <a href=\'tel:0800121314\'>0800 12 13 14</a> we can manually check and see if any solutions may be available for you.',
  COVERAGE_BUTTON_NEXT: 'Request a callback',

  DETAILS_TITLE: 'Great News! You\'re in coverage',
  DETAILS_LINE_1: 'We will need to confirm your contact details before we can start provisioning your new property with reliable, fast broadband',
  DETAILS_LINE_2: 'Please note that completing this form does not guarantee a service will be provided. Service is subject to line of sight being possible from our network, which will be validated once your order has been received.',
  DETAILS_LABEL_CONTACT_NAME: 'Full Name',
  DETAILS_ERROR_CONTACT_NAME: 'Name is required.',
  DETAILS_LABEL_CONTACT_EMAIL: 'Email',
  DETAILS_ERROR_CONTACT_EMAIL: 'A valid email is required.',
  DETAILS_LABEL_CONTACT_CELLPHONE: 'Mobile',
  DETAILS_ERROR_CONTACT_CELLPHONE: 'A valid mobile number is required.',
  DETAILS_HINT_CONTACT_CELLPHONE: 'Our technicians may reach out to coordinate the installation',
  DETAILS_LABEL_MOVING_DATE: 'What date are you moving?',
  DETAILS_ERROR_MOVING_DATE: 'A valid date is required.',
  DETAILS_HINT_MOVING_DATE: 'Your date doesn\'t have to be exact. We will ask you some follow up questions to ensure an optimal install date is possible.<br/> Install is subject to lead time and installer availability.',
  DETAILS_INSTALLERS_TITLE: 'This is what our<br />installers want to know',
  DETAILS_INSTALLERS_SUBTITLE: 'How many levels is your property?',
  DETAILS_INSTALLERS_LABEL_SINGLE: 'Single',
  DETAILS_INSTALLERS_LABEL_MULTI: 'Multiple',
  DETAILS_INSTALLERS_ERROR: 'Property details are required',
  DETAILS_BUTTON_NEXT: 'Next step please',

  DETAILS_DISCONNECTION_DATE: 'When are you moving?',

  DETAIL_NO_COVERAGE_TITLE: 'Sorry we could not find coverage in your area.',
  DETAILS_NO_COVERAGE_LINE_1: 'Please fill out the contact form and we\'ll double check.',
  DETAILS_NO_COVERAGE_LABEL_CALLBACK: 'Contact me when coverage is available at my address.',

  CONFIRM_TITLE: 'you are almost finished, just to confirm...',
  CONFIRM_MOVING_DATE: 'You are moving on',
  CONFIRM_CONTRACT_TERM: 'Your contract will be renewed for',
  CONFIRM_CONTRACT_MONTHS: 'Months',
  CONFIRM_MOVING_TO: 'You are moving to',
  CONFIRM_MOVING_FROM: 'From',
  CONFIRM_EMAIL: 'and we can contact you on',
  CONFIRM_OR: 'or',
  CONFIRM_RELOCATION_FEE: 'Please note there will be a relocation fee of:',
  CONFIRM_NO_RELOCATION_FEE: 'No relocation charge applies.',
  CONFIRM_BUTTON: 'Book in my relocation',

  CONFIRM_DISCONNECTION_TITLE: 'Just to confirm your details',
  CONFIRM_DISCONNECTION_BUTTON: 'Book in my Disconnection',
  CONFIRM_DISCONNECTION_NEW_ADDRESS: 'Your new address is',
  CONFIRM_DISCONNECTION_OLD_ADDRESS: 'The address to disconnect is',
  CONFIRM_CALLBACK_TRUE: 'We will double check coverage for you and send you an email to confirm whether we can get you connected.',
  CONFIRM_CALLBACK_FALSE: 'Check back again soon to see if your new address comes into coverage.',

  CLOSE_WINDOW_MODAL_TITLE: 'Request Recieved',

  CLOSE_WINDOW_MODAL_NEXT: 'Thanks for lodging your relocation request with us.<br/><br/>A ticket is now with our rural sales team and they will be in touch via email shortly to let you know what the next steps are.',
  CLOSE_WINDOW_MODAL_DESCRIPTION: 'Thank you for your submission,<br/>you can close this window and return the customer portal<br /><br />',
  CLOSE_WINDOW_MODAL_BUTTON: 'Complete'
}
