
import { defineComponent } from 'vue'
import { geocodeResult, formData, relocationFee, isConfirmed, customer, loading } from '../state'
import { l } from '../constants'
import { updateRelocationService } from '../services/RelocationService'

export default defineComponent({
  name: 'Confirmation',

  setup (): any {
    loading.value = false
    return { geocodeResult, formData, relocationFee, isConfirmed, customer, loading }
  },

  data (): unknown {
    return {
      labels: l,
      showCompletionModal: false
    }
  },

  methods: {
    confirm (): void {
      loading.value = true

      updateRelocationService().then((res) => {
        loading.value = false
        this.showCompletionModal = true
      })
    },
    closeWindow (): void {
      window.close()
    }
  }

})
